import { Link } from "react-router-dom"


const HomePage = () => {
    return (
        <>
            <div className="container txers-main">
                <div className="txers">
                    <div className="txers-img">
                        <img src="img/txer.png" alt="Txer" />
                    </div>
                    <div className="txers-text">
                        <div className="txers-text__title">
                            <p>TXERS</p>
                            <span>
                                The transaction needs someone <br /> to deliver it
                            </span>
                        </div>
                    </div>
                    <Link to="/mint" style={{color: '#253258'}}>
                        <div className="txers-btn">
                            MINT
                        </div>
                    </Link>
                </div>
            </div>
            <div className="footer">
                <p>
                    2023 © txers
                </p>
            </div>
        </>
    )
}

export { HomePage }