/* eslint-disable react/jsx-no-comment-textnodes */
import $ from "jquery";



const FaqPage = () => {


    const FAQ = [
        {
            title: "// txers in a few words( _?)", content: "4444 pixel characters 64×64 called «txers» (from word tx = transaction) running through blockchain, driving transactions and keeping the network moving."
        },
        {
            title: "// what the team behind( _?)", content: "my name is gone gordoner (not gordon goner, okay). https://twitter.com/gonegordoner\nI woke up in the middle of the night with a great idea. I'm going to spoof the creator of Yuga Labs on my Twitter. no wicked jokes - I tweet funny things, but mostly just retweet. And thus did I proclaim myself a «future influencer», and soon shall all know me as the creator of this universe, with the first players being none other than the txers..."
        },
        {
            title: "// distribution and mint price( _?)", content: "txlist mint = first nft for free, remaining 0.008 eth\npublic mint = 0.009 eth"
        },
        {
            title: "// mint date( _?)", content: "to be announced... to be announced... to be announced..."
        },
        // {
        //     title: "// overallocation( _?)", content: "this parameter is irrelevant in our mint model. We will have both a public mint and a whitelist mint at the same time, just on different terms on a first-come, first-served basis.\n\nThe main reason is to allow everyone to be in the same boat as us, but at the same time to give the advantage to the whitelist."
        // },
        {
            title: "// what’s next(_?)", content: "Ain’t got no roadmap, but after we kill the launch, we gotta build somethin' up. We’re all about that pixel art and we dig good storytelling (check out the «story and lore» section for proof).\n\nJust throwin' out ideas for the future, like maybe makin' some kinda «blockchainverse» ecosystem (okay, the name needs work, but you get the idea). Populate it with characters related to the blockchain of the future, post-disaster. Our motto: underpromise and overdeliver."
        },
        {
            title: "// Wen next chapter in the story(_?)", content: "Yo, we came up with a sick storytelling mechanic. You gotta be a holder and own a certain amount of txers to unlock new chapters of the story. And whoever holds the most will get the exclusive look at the ending!"
        },
    ]


    const accord = FAQ.map((el, i) => {
        return (
            <>
                <div className="accordion"  id={"accord-"+i} onClick={() => According(i) }>
                    <div className="accordion-row"  >
                        <div className="accordion-title">
                         {el.title}
                        </div>
                        <div className="accordion-close">
                            <span></span>
                        </div>
                    </div>
                    <div className="accord-text green accord-hidden" >
                        {el.content}
                    </div>
                </div>
                <hr/>
            </>

        )
    })

    const According = (event) => {
        let id = "#accord-"+ event;
        let child = $(id).children(".accord-text");
        let close = $(id).children(".accordion-row").children(".accordion-close").children("span");
        if (!$(id).hasClass('clicked')) {
            $(id).addClass('clicked');
            close.css("transform", "rotate(180deg)");
            child.toggleClass("accord-open");
        } else {
            close.css("transform", "rotate(90deg)");
            $(id).removeClass('clicked');
            child.removeClass("accord-open");
        }
    }

    return (
        <>
            <div className="container d-flex justify-content-center">
                <div className="mint-controller">
                    <div className="mint-message faq">
                        // faq section has sucessfully loaded. Tx confirmed.
                    </div>
                    <div className="mint-message faq">
                        //
                    </div>
                    <div className="mint-message faq">
                        // ...
                    </div>
                    <hr />
                    {accord}
                </div>
            </div>
            <div className="footer" style={{position: "relative"}}>
                <p>
                    2023 © txers
                </p>
            </div>
        </>
    )
}

export { FaqPage }