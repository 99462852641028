import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from "buffer/";
import whiteistedaddress from './config/tree'
window.Buffer = window.Buffer || Buffer;

export function bake_cookie(name, value) {
    var data = new Date(new Date().getTime() + (30 * 1000));
    var cookie = [name, '=', JSON.stringify(value), '; domain_.', window.location.host.toString(), '; path=/;', 'expires=' + data.toUTCString()].join('');
    document.cookie = cookie;
}

// reads a cookie according to the given name
export function read_cookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result = result != null ? JSON.parse(result[1]) : [];
    return result;
}

export function delete_cookie(name) {
    document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain.', window.location.host.toString()].join('');
}



// Find Hex Proof
export function findHexProof(addr){
    try {
        let indexOfArray = whiteistedaddress.indexOf(addr);
        let leafNode = whiteistedaddress.map(addr => keccak256(addr));
        const merkleTree = new MerkleTree(leafNode, keccak256, { sortPairs: true });
        const clamingAddress = leafNode[indexOfArray];
        const hexProof = merkleTree.getHexProof(clamingAddress);
        console.log("N раз", 'hexProof');
        return hexProof
    } catch (error) {
        console.log(error, 'hexProof');
        return "Not WL"
    }

}