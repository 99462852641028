import { Link, Outlet, useLocation } from "react-router-dom"
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import { useAccount, useEnsName } from 'wagmi';
import { useEffect } from 'react';
import $ from "jquery";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;


const Layout = () => {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { address, isConnected, isDisconnected } = useAccount();
    const ensName = useEnsName({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
        },
        onError(error) {
            console.log('Error', error)
        },
    })


    const { connector: activeConnector } = useAccount()

    useEffect(() => {
        const handleConnectorUpdate = ({ account, chain }) => {
            if (account) {
                console.log('new account', account)
                window.location.reload()
            } else if (chain) {
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate)
        }
    }, [activeConnector])


    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.menu').removeClass('active');
            $('.social').removeClass('active');
            $('body').removeClass('lock');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.menu').removeClass('active');
            $('.social').removeClass('active');
            $('body').removeClass('lock');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $('.menu').addClass('active');
            $('.social').addClass('active');
            $('body').addClass('lock');
        }
    }
    const location = useLocation();

    return (
        <>
            <header>
                <div className="menu" onClick={() => clicker()}>
                    <ul className="menu-list">
                        <li className="menu-item">
                            {
                                location.pathname === "/" ? [
                                    <Link to="/" className="select-menu" id="home">
                                        main
                                    </Link>
                                ] : [
                                    <Link to="/" id="home">
                                        main
                                    </Link>
                                ]
                            }
                        </li>
                        <li className="menu-item">
                            {
                                location.pathname === "/story" ? [
                                    <Link to="/story" className="select-menu" id="store">
                                        story & Lore
                                    </Link>
                                ] : [
                                    <Link to="/story" id="store">
                                        story & Lore
                                    </Link>
                                ]
                            }
                        </li>
                        <li className="menu-item">
                            {
                                location.pathname === "/faq" ? [
                                    <Link to="/faq" className="select-menu" id="faq">
                                        fAQ
                                    </Link>
                                ] : [
                                    <Link to="/faq" id="faq">
                                        fAQ
                                    </Link>
                                ]
                            }
                        </li>
                        {
                            isConnected && (
                                <li className="menu-item">
                                    <Link onClick={event => {
                                        openAccountModal();
                                    }} id="connect">
                                        {ensName.data != null ? [
                                            <>
                                                <span>{ensName.data}</span>
                                            </>
                                        ] : [
                                            <>
                                                <span>{truncate(address, 4) + address.substr(-4, 4)}</span>
                                            </>
                                        ]}
                                    </Link>
                                </li>
                            )
                        }
                        {
                            isDisconnected && (
                                <li className="menu-item">
                                    <Link onClick={openConnectModal} id="connect">
                                        Connect
                                    </Link>
                                </li>
                            )
                        }
                        <li className="menu-item">
                            {
                                location.pathname === "/mint" ? [
                                    <Link to="/mint" id="mint" className="select-menu" >
                                        mint
                                    </Link>
                                ] : [
                                    <Link to="/mint" id="mint">
                                        mint
                                    </Link>
                                ]
                            }
                        </li>
                    </ul>
                </div>
                <div className="social">
                    <div className="social-img">
                        <a href="https://opensea.io/collection/txersofficial">
                            <img src="img/op.svg" alt="Opensea" />
                        </a>
                    </div>
                    <div className="social-img">
                        <a href="https://twitter.com/TxersRun">
                            <img src="img/tw.svg" alt="Twitter" />
                        </a>
                    </div>
                    <div className="social-img">
                        <a href="https://discord.com/invite/NErX9fCAYP">
                            <img src="img/ds.svg" alt="Discord" />
                        </a>
                    </div>
                    <div className="social-img">
                        <a href="https://etherscan.io/address/0x6619cf3c61bc6a495791ae1a327d52d152cbe1b4">
                            <img src="img/eth.svg" alt="Etherscan" />
                        </a>
                    </div>
                </div>
                <div className="mob-menu"></div>
                <div className="header__burger" id="buger" onClick={() => burger()}>
                    <span></span>
                </div>
            </header>
            <Outlet />
            <div className="bg-color"></div>
        </>
    )
}


export { Layout }