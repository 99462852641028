import React from "react";
import { Routes, Route  } from "react-router-dom"
import { Layout } from "./components/Layout";
import { FaqPage } from "./pages/faq";
import { MintPage } from "./pages/mint";
import { StoryPage } from "./pages/story";
import { HomePage } from "./pages/home";



function App() {
    
    return (

        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/mint" element={<MintPage />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/story" element={<StoryPage />} />
                </Route>
            </Routes>
        </>

    );
}

export default App;
