import { useState, useRef, useEffect } from "react";
import waves from "../music/1.mp3";
import { useLocation } from "react-router-dom"


const MusicComp = () => {
    const location = useLocation();
    const [Mucis, setMucis] = useState(true);
    let audio = useRef();

    useEffect(() => {
        audio.current = new Audio(waves)
        audio.current.play()
    }, [])

    useEffect(() => {
        return () => {
            audio.current.pause()
            console.log("in cleanup")
        }
    }, [])

    return (
        <>
            {
                location.pathname === "/story" ? [
                    <div className="music">
                        <>
                            {
                                Mucis === true ? [
                                    <img src="music/music-on.svg" alt="Music-on" onClick={() => {
                                        setMucis(!Mucis);
                                        audio.current.pause();
                                    }} />
                                ] : [
                                    <img src="music/music-off.svg" alt="Music-on" onClick={() => {
                                        setMucis(!Mucis);
                                        audio.current.play();
                                    }} />
                                ]
                            }
                        </>
                    </div>
                ] : [
                    <>
                        {console.log()}
                    </>
                ]}
        </>
    )
}

export { MusicComp }