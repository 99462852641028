/* eslint-disable no-useless-escape */
/* eslint-disable no-regex-spaces */
/* eslint-disable react/jsx-no-comment-textnodes */

import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useAccount, usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction } from 'wagmi';
import contractInterface from "../config/abi.json";
import $ from "jquery";
import {
    useConnectModal,
} from '@rainbow-me/rainbowkit';
import { bake_cookie, read_cookie, findHexProof } from '../cookie';
import { useDebounce } from 'use-debounce';
import Popup from '../components/popup'
const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;


const MintPage = () => {
    const toFix = 3; //4 для тестового
    const callbackRef = useCallback(inputElement => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);
    const { openConnectModal } = useConnectModal();
    const [errorPopup, setErrorPopup] = useState(false);
    const [PbgoodPopup, setPbGoodPopup] = useState(false);
    const [txGoodPopup, setTxGoodPopup] = useState(false);
    const [txHash, setTxHash] = useState("");
    const { address, isConnected, isDisconnected, isConnecting } = useAccount();
    const [wlWallet, setwlWallet] = useState(true);
    const [inputValue, setinputValue] = useState(3);
    const [isLoading, setisLoading] = useState(false);
    const [Loading, setLoading] = useState(true); //Тут true должно быть
    const [debounceValue] = useDebounce(inputValue, 500);
    var proof = "proof1_" + address
    var addr = "address_" + address
    const [isReads, setisReads] = useState({
        maxToken: 4444,
        mintMax: 5,
        pbmintPrice: 9000000000000000,
        publicMintEnabled: "false",
        totalSupply: 0,
        txlistMintEnabled: "false",
        txlistMintMax: 3,
        txlistMintPrice: 8000000000000000,
        balanceOf: 0,
    });
    const funcReadName = ['maxToken', 'mintMax', 'pbmintPrice', 'publicMintEnabled', 'totalSupply', 'txlistMintEnabled', 'txlistMintMax', 'txlistMintPrice', 'balanceOf'];
    const contractConfig = {
        CONTRACT_ADDRESS: "0x6619cf3c61bc6a495791ae1a327d52d152cbe1b4" // mainnet 0x6619cf3c61bc6a495791ae1a327d52d152cbe1b4 testnet 0xdfbfc5f10455831e8bbcefc94df41d8126b3a756
    }

    const getData = async () => {
        setisLoading(true);
        if (read_cookie(addr).length !== 0) {
            setwlWallet(read_cookie(addr))
        } else {
            await axios.get(
                `https://script.google.com/macros/s/AKfycbyebRvu2VgaW1bE6oKpSCnfJk9uOijecFvshko0p7UID8-nSuHly10B28Cw0ZFqVNj0/exec?whitelist=${address}`
            ).then(res => {
                res.data["wl"].length === 0 ? setwlWallet(false) : setwlWallet(true);
                res.data["wl"].length === 0 ? bake_cookie(addr, false) : bake_cookie(addr, true);
                bake_cookie(proof, findHexProof(address.toLowerCase()))
                console.log(read_cookie(addr))
            })
        }
        setisLoading(false);
    };

    useEffect(() => {
        isConnected && getData();
    }, [isConnected])

    const { config: configpbmint, isError: pbMintError } = usePrepareContractWrite({
        address: contractConfig.CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: 'pbMint',
        args: [debounceValue],
        overrides: {
            value: (isReads.pbmintPrice * debounceValue).toString(),
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    //code=INVALID_ARGUMENT - когда инпут пуст
    //code=UNPREDICTABLE_GAS_LIMIT  невозмоэно расчитать газ (когда кол-во привышено например)
    //code=INSUFFICIENT_FUNDS -  недостаточно средств


    const { config: wlmint, isError: txMintError } = usePrepareContractWrite({
        address: contractConfig.CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: 'txlistMint',
        args: [
            debounceValue,
            read_cookie(proof),
        ],
        overrides: {
            value: Number(isReads.balanceOf) === 0 ? (isReads.txlistMintPrice * (debounceValue - 1)).toString() : (isReads.txlistMintPrice * debounceValue).toString(),
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const { data: txdata, write: txMint } = useContractWrite({
        ...wlmint,
        onError(error) {
            setErrorPopup(true)
            console.log('Error', error)
        },
    })

    const { isLoading: txLoading } = useWaitForTransaction({
        hash: txdata?.hash,
        onSuccess(data) {
            setTxGoodPopup(true)
            setTxHash(txdata?.hash)
            console.log("Запись ХЭШ")
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const { data: pbdata, write: mint } = useContractWrite({
        ...configpbmint,
        onError(error) {
            setErrorPopup(true)
            console.log('Error', error)
        },
    })

    const { isLoading: pbLoading } = useWaitForTransaction({
        hash: pbdata?.hash,
        onSuccess(data) {
            setPbGoodPopup(true)
            setTxHash(pbdata?.hash)
            console.log("Запись ХЭШ")
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    const counterMint = (event) => {
        if (event.value.length > 1) {
            event.value = event.value.slice(0, -event.value.length);
            event.value = 1
        }
        if (event.value === "") {
            $(".puls-input").css('background', 'red')
        } else {
            $(".puls-input").css('background', '#27AE60')
        }
        if (event.value === "0") {
            event.value = event.value.slice(0, -event.value.length);
            event.value = 1
        }
        if (event.id === 'txinput' && Number(event.value) > Number(isReads.txlistMintMax)) {
            event.value = Number(isReads.txlistMintMax)
        }
        if (event.id === 'pbinput' && Number(event.value) > Number(isReads.mintMax)) {
            event.value = Number(isReads.mintMax)
        }
        if (event.value < 0) {
            setinputValue(Math.abs(event.value));
        }
        if (event.value === "") {
            $('#txinput').css("background", "unset");
            $('#pbinput').css("background", "unset");
            $('.input-number').css("margin-left", "0px");
        }
        else if (event.value.length > 0) {
            let width = 15 * event.value.length;
            $('.input-number').css("margin-left", "16px");
            $('#txinput').css("width", width + 'px');
            $('#txinput').css("background", "#27AE60");
            $('#pbinput').css("width", width + 'px');
            $('#pbinput').css("background", "#27AE60");
        }
        else {
            $('.input-number').css("margin-left", "16px");
            $('#txinput').css("background", "#27AE60");
            $('#pbinput').css("background", "#27AE60");
        }
        setinputValue(event.value)
    }

    useContractReads({
        contracts: [
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[0],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[1],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[2],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[3],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[4],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[5],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[6],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[7],
            },
            {
                address: contractConfig.CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcReadName[8],
                args: [address],
            },
        ],
        watch: true,
        cacheTime: 10000,
        onError(error) {
            console.log('Error', error)
        },
        onSuccess(data) {
            if (isDisconnected) {
                console.log("Отключен")
            } else {
                console.log('Success', data?.toString())
                let result = {}
                funcReadName.map((el, i) => {
                    result[el] = data[i]?.toString()
                    return (true)
                })
                setisReads(result);
            }
            setLoading(false)
        },
    })



    return (
        <>
            <div className="container d-flex justify-content-center">
                <div className="mint-controller">
                    <div className="mint-message mint">
                        // minting terminal has been successfully loaded. tx confirmed.
                    </div>
                    <div className="mint-message mint">
                        // ..
                    </div>
                    <div className="mint-message mint">
                        // ...
                    </div>
                    {
                        isReads.publicMintEnabled === "false" && isReads.txlistMintEnabled === "false" ? [
                            <>
                                {
                                    isDisconnected ? [
                                        <hr style={{ background: '#F2994A' }} />
                                    ] : [
                                        wlWallet === false ? [
                                            <hr style={{ background: '#EB5757' }} />
                                        ] : [
                                            <hr />
                                        ]
                                    ]

                                }
                                {
                                    Loading ? [
                                        <>
                                            <div className="mint-message mint">
                                                // Loading...
                                            </div>
                                            <div className="mint-message mint">
                                                // Loading...
                                            </div>
                                        </>
                                    ] : [
                                        <>
                                            <div className="mint-message mint">
                                                // minted: {isReads.totalSupply} / {isReads.maxToken}
                                            </div>

                                            {
                                                wlWallet ? [
                                                    <div className="mint-message mint">
                                                            // max per wallet: {isReads.txlistMintMax}
                                                    </div>
                                                ] : [
                                                    <div className="mint-message mint">
                                                            // max per wallet: {isReads.mintMax}
                                                    </div>
                                                ]
                                            }
                                        </>
                                    ]
                                }
                                {
                                    isDisconnected && (
                                        <div className="mint-message green" style={{ color: "#F2994A" }}>
                                            // Connect your wallet for verification
                                        </div>
                                    )
                                }
                                {
                                    isConnected && (
                                        <>
                                            {
                                                isLoading ? [
                                                    <div className="mint-message green" style={{ color: "#27AE60" }}>
                                                            // Checking ....
                                                    </div>
                                                ] : [
                                                    <>
                                                        {
                                                            wlWallet === false ? [
                                                                <div className="mint-message green" style={{ color: "#EB5757" }}>
                                                                     // you’re not whitelisted. the information may not have been updated yet.
                                                                </div>
                                                            ] : [
                                                                <div className="mint-message green" style={{ color: "#27AE60" }}>
                                                                    // congrats! you’re whitelisted.
                                                                </div>
                                                            ]
                                                        }
                                                    </>
                                                ]
                                            }

                                        </>
                                    )
                                }
                                {
                                    isConnecting && (
                                        <div className="mint-message green" style={{ color: "#27AE60" }}>
                                            // Connecting....
                                        </div>
                                    )
                                }
                                {
                                    Loading ? [
                                        <>
                                            <div className="mint-message mint">
                                                    // loading...
                                            </div>
                                            <div className="input-mint">
                                                <div className="mint-message mint">
                                                    // loading...
                                                </div>
                                            </div>
                                        </>
                                    ] : [
                                        <>
                                            {
                                                wlWallet ? [
                                                    <div className="mint-message mint">
                                                    // your mint price: free for first, {((isReads.txlistMintPrice / 1000000000000000000)).toFixed(toFix)} for rest
                                                    </div>
                                                ] : [
                                                    <div className="mint-message mint">
                                                    // your mint price: {((isReads.pbmintPrice / 1000000000000000000)).toFixed(toFix)}
                                                    </div>
                                                ]
                                            }
                                            <div className="input-mint">
                                                {
                                                    wlWallet ? [
                                                        <div className="mint-message mint">
                                                            // enter your mint amount (max {isReads.txlistMintMax}):
                                                        </div>
                                                    ] : [
                                                        <div className="mint-message mint">
                                                            // enter your mint amount (max {isReads.mintMax}):
                                                        </div>
                                                    ]
                                                }
                                                {
                                                    wlWallet ? [
                                                        <div className="input-number">
                                                            <input ref={callbackRef} onKeyDown={(e) => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()} required type="number" id="txinput" min="1" max={isReads.txlistMintMax} value={inputValue} onChange={event => counterMint(event.target)} />
                                                        </div>
                                                    ] : [
                                                        <div className="input-number">
                                                            <input ref={callbackRef} onKeyDown={(e) => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()} required type="number" id="pbinput" min="1" max={isReads.mintMax} value={inputValue} onChange={event => counterMint(event.target)} />
                                                        </div>
                                                    ]
                                                }
                                                <div className="puls-input animate__flash"></div>
                                            </div>
                                            <div className="mint-btn">
                                                <div className="txers-btn">
                                                    Not open
                                                </div>
                                                {
                                                    wlWallet ? [
                                                        <div className="mint-btn__text">
                                                            total: <br />{Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) === 0 ? [
                                                                'Free'
                                                            ] : [
                                                                Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) < 0 ? "0 eth" : Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) + ' eth'
                                                            ]}
                                                        </div>
                                                    ] : [
                                                        <div className="mint-btn__text">
                                                            total: <br />{Number(((isReads.pbmintPrice / 1000000000000000000) * inputValue).toFixed(toFix))} eth
                                                        </div>
                                                    ]
                                                }
                                            </div>
                                        </>
                                    ]
                                }
                            </>
                        ] : [
                            <>
                                {
                                    isDisconnected ? [
                                        <hr style={{ background: '#F2994A' }} />
                                    ] : [
                                        wlWallet === false ? [
                                            <hr style={{ background: '#EB5757' }} />
                                        ] : [
                                            <hr />
                                        ]
                                    ]
                                }
                                <div className="mint-message mint">
                                    // minted: {isReads.totalSupply} / {isReads.maxToken}
                                </div>
                                {
                                    wlWallet ? [
                                        Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                            <div className="mint-message mint">
                                                // max per wallet: {isReads.mintMax}
                                            </div>
                                        ] : [
                                            <div className="mint-message mint">
                                                // max per wallet: {isReads.txlistMintMax}
                                            </div>
                                        ]
                                    ] : [
                                        <div className="mint-message mint">
                                            // max per wallet: {isReads.mintMax}
                                        </div>
                                    ]
                                }

                                {
                                    isDisconnected && (
                                        <div className="mint-message green" style={{ color: "#F2994A" }}>
                                            // connect a wallet to see if you're on the Whitelist
                                        </div>
                                    )
                                }
                                {
                                    isConnected && (
                                        <>
                                            {
                                                isLoading ? [
                                                    <div className="mint-message green" style={{ color: "#27AE60" }}>
                                                            // Checking ....
                                                    </div>
                                                ] : [
                                                    <>
                                                        {
                                                            wlWallet === false ? [
                                                                isReads.publicMintEnabled === "false" ? [
                                                                    <div className="mint-message green" style={{ color: "#EB5757" }}>
                                                                        // you’re not whitelisted. the information may not have been updated yet.
                                                                    </div>
                                                                ] : [

                                                                ]
                                                            ] : [
                                                                <div className="mint-message green" style={{ color: "#27AE60" }}>
                                                                    // congrats! you’re whitelisted.
                                                                </div>
                                                            ]
                                                        }
                                                    </>
                                                ]
                                            }

                                        </>
                                    )
                                }
                                {
                                    isConnecting && (
                                        <div className="mint-message green" style={{ color: "#27AE60" }}>
                                            // Connecting....
                                        </div>
                                    )
                                }
                                {
                                    isDisconnected ? [
                                        <div className="mint-message mint">
                                            // public mint price: {((isReads.pbmintPrice / 1000000000000000000)).toFixed(toFix)} eth
                                        </div>
                                    ] : [
                                        wlWallet ? [
                                            Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                <div className="mint-message mint">
                                                    // your mint price: {((isReads.pbmintPrice / 1000000000000000000)).toFixed(toFix)} eth
                                                </div>
                                            ] : [
                                                <div className="mint-message mint">
                                                    {'// your mint price: free for first, ' + ((isReads.txlistMintPrice / 1000000000000000000)).toFixed(toFix) + ' eth for rest'}
                                                </div>
                                            ]
                                        ] : [
                                            <div className="mint-message mint">
                                                // your mint price: {((isReads.pbmintPrice / 1000000000000000000)).toFixed(toFix)} eth
                                            </div>
                                        ]
                                    ]
                                }
                                {
                                    isConnected ? [
                                        wlWallet ? [
                                            Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                <>
                                                    <div className="mint-message mint">
                                                        // you already have: {isReads.balanceOf} nft
                                                    </div>
                                                    {
                                                        ((Number(isReads.mintMax) - (Number(isReads.balanceOf) - Number(isReads.txlistMintMax))) - inputValue) < 0 ? [
                                                            <div className="mint-message mint">
                                                                // LIMIT EXCEEDED BY:
                                                                <span style={{ color: '#EB5757' }}>
                                                                    {" " + Math.abs((Number(isReads.mintMax) - (Number(isReads.balanceOf) - Number(isReads.txlistMintMax))) - inputValue)} nft
                                                                </span>
                                                            </div>
                                                        ] : [
                                                            <div className="mint-message mint">
                                                                // you can still mint:
                                                                <span style={{ color: '#27AE60' }}>
                                                                    {" " + ((Number(isReads.mintMax) - (Number(isReads.balanceOf) - Number(isReads.txlistMintMax))) - inputValue) + ' nft'}
                                                                </span>
                                                            </div>
                                                        ]
                                                    }

                                                </>
                                            ] : [
                                                <>
                                                    <div className="mint-message mint">
                                                        // you already have: {isReads.balanceOf} nft
                                                    </div>
                                                    {
                                                        ((Number(isReads.txlistMintMax) - Number(isReads.balanceOf)) - inputValue) < 0 ? [
                                                            <div className="mint-message mint">
                                                                // LIMIT EXCEEDED BY:
                                                                <span style={{ color: '#EB5757' }}>
                                                                    {" " + Math.abs((Number(isReads.txlistMintMax) - Number(isReads.balanceOf)) - inputValue)} nft
                                                                </span>
                                                            </div>
                                                        ] : [
                                                            <div className="mint-message mint">
                                                                // you can still mint:
                                                                <span style={{ color: '#27AE60' }}>
                                                                    {" " + ((Number(isReads.txlistMintMax) - Number(isReads.balanceOf)) - inputValue) + ' nft'}
                                                                </span>
                                                            </div>
                                                        ]
                                                    }
                                                </>
                                            ]
                                        ] : [
                                            <>
                                                <div className="mint-message mint">
                                                    // you already have: {isReads.balanceOf} nft
                                                </div>
                                                {
                                                    ((Number(isReads.mintMax) - Number(isReads.balanceOf)) - inputValue) < 0 ? [
                                                        <div className="mint-message mint">
                                                                // LIMIT EXCEEDED BY:
                                                            <span style={{ color: '#EB5757' }}>
                                                                {" " + Math.abs((Number(isReads.mintMax) - Number(isReads.balanceOf)) - inputValue)} nft
                                                            </span>
                                                        </div>
                                                    ] : [
                                                        <div className="mint-message mint">
                                                                // you can still mint:
                                                            <span style={{ color: '#27AE60' }}>
                                                                {" " + ((Number(isReads.mintMax) - Number(isReads.balanceOf)) - inputValue) + ' nft'}
                                                            </span>
                                                        </div>
                                                    ]
                                                }
                                            </>
                                        ]
                                    ] : [
                                        isConnecting && (
                                            <>
                                                <div className="mint-message mint">
                                                    // loading...
                                                </div>
                                                <div className="mint-message mint">
                                                    // loading...
                                                </div>
                                            </>
                                        )
                                    ]
                                }
                                <div className="input-mint">
                                    {
                                        wlWallet ? [
                                            Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                <div className="mint-message mint">
                                                    // enter your mint amount (max {isReads.mintMax}):
                                                </div>
                                            ] : [
                                                <div className="mint-message mint">
                                                    // enter your mint amount (max {isReads.txlistMintMax}):
                                                </div>
                                            ]

                                        ] : [
                                            <div className="mint-message mint">
                                                // enter your mint amount (max {isReads.mintMax}):
                                            </div>
                                        ]
                                    }
                                    {
                                        wlWallet ? [
                                            Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                <div className="input-number">
                                                    <input ref={callbackRef} onKeyDown={(e) => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()} required type="number" id="pbinput" min="1" max={isReads.mintMax} value={inputValue} onChange={event => counterMint(event.target)} />
                                                </div>
                                            ] : [
                                                <div className="input-number">
                                                    <input ref={callbackRef} onKeyDown={(e) => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()} required type="number" id="txinput" min="1" max={isReads.txlistMintMax} value={inputValue} onChange={event => counterMint(event.target)} />
                                                </div>
                                            ]
                                        ] : [
                                            <div className="input-number">
                                                <input ref={callbackRef} onKeyDown={(e) => ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault()} required type="number" id="pbinput" min="1" max={isReads.mintMax} value={inputValue} onChange={event => counterMint(event.target)} />
                                            </div>
                                        ]
                                    }
                                    <div className="puls-input animate__flash"></div>
                                </div>
                                {
                                    isDisconnected ? [
                                        <div className="mint-btn">
                                            <div className="txers-btn" onClick={openConnectModal}>
                                                Connect
                                            </div>
                                        </div>
                                    ] : [
                                        wlWallet ? [
                                            isReads.txlistMintEnabled === "true" ? [
                                                <div className="mint-btn">
                                                    {
                                                        Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                            <div className="txers-btn" id='pbMint' onClick={() => {
                                                                pbMintError ? setErrorPopup(true) : !pbLoading ? mint() : console.log("Идет транзакция")
                                                            }}>
                                                                {pbLoading ? 'Minting...' : 'PMint'}
                                                            </div>
                                                        ] : [
                                                            <div className="txers-btn" onClick={() => {
                                                                txMintError ? setErrorPopup(true) : !txLoading ? txMint() : console.log("Идет транзакция")
                                                            }}>
                                                                {txLoading ? 'Minting...' : 'Mint'}
                                                            </div>
                                                        ]
                                                    }
                                                    {
                                                        Number(isReads.balanceOf) === 0 ? [
                                                            <div className="mint-btn__text">
                                                                total: <br />{Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) === 0 ? [
                                                                    'Free'
                                                                ] : [
                                                                    Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) < 0 ? "0 eth" : Number(((isReads.txlistMintPrice / 1000000000000000000) * (inputValue - 1)).toFixed(toFix)) + ' eth'
                                                                ]}
                                                            </div>
                                                        ] : [
                                                            Number(isReads.balanceOf) >= Number(isReads.txlistMintMax) ? [
                                                                <div className="mint-btn__text">
                                                                    total: <br />{Number(((isReads.pbmintPrice / 1000000000000000000) * inputValue).toFixed(toFix))} eth
                                                                </div>
                                                            ] : [
                                                                <div className="mint-btn__text">
                                                                    total: <br />{Number(((isReads.txlistMintPrice / 1000000000000000000) * inputValue).toFixed(toFix))} eth
                                                                </div>
                                                            ]
                                                        ]
                                                    }
                                                </div>
                                            ] : [
                                                <div className="mint-btn">
                                                    <div className="txers-btn">
                                                        Not Open
                                                    </div>
                                                </div>
                                            ]
                                        ] : [
                                            isReads.publicMintEnabled === "true" ? [
                                                <div className="mint-btn">
                                                    <div className="txers-btn" id='pbMint' onClick={() => {
                                                        pbMintError ? setErrorPopup(true) : !pbLoading ? mint() : console.log("Идет транзакция")
                                                    }}>
                                                        {pbLoading ? 'Minting...' : 'Mint'}
                                                    </div>
                                                    <div className="mint-btn__text">
                                                        total: <br />{Number(((isReads.pbmintPrice / 1000000000000000000) * inputValue).toFixed(toFix))} eth
                                                    </div>
                                                </div>
                                            ] : [
                                                <div className="mint-btn">
                                                    <div className="txers-btn">
                                                        Not Open
                                                    </div>
                                                </div>
                                            ]
                                        ]
                                    ]
                                }
                            </>
                        ]
                    }
                </div>
            </div>
            <Popup active={errorPopup} setActive={setErrorPopup} >
                <div className="popup-img">
                    <img src="img/error.svg" alt="Error" />
                </div>
                <div className="popup-title">
                    something went wrong
                </div>
                <div className="popup-text">
                    possible reasons: <br />
                    – you have not enough eth <br />
                    – you are trying to mint more than allowed<br />
                    – you didn’t retweet our pinned post (okay it’s joke)<br />
                </div>
            </Popup>
            <Popup active={PbgoodPopup} setActive={setPbGoodPopup} >
                <div className="popup-img">
                    <img src="img/good.svg" alt="Error" />
                </div>
                <div className="popup-title">
                    successfully minted!
                </div>
                <div className="popup-text">
                    your tx here: <a href={`https://etherscan.io/tx/${pbdata?.hash}`}>{pbdata?.hash !== undefined ? [truncate(txHash, 4) + txHash.substr(-4, 4)] : ["0x...346 "]}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg></a> <br /><br />
                    txer are happy! now it has a job to deliver your transaction.
                </div>
            </Popup>
            <Popup active={txGoodPopup} setActive={setTxGoodPopup} >
                <div className="popup-img">
                    <img src="img/good.svg" alt="Error" />
                </div>
                <div className="popup-title">
                    successfully minted!
                </div>
                <div className="popup-text">
                    your tx here: <a href={`https://etherscan.io/tx/${txdata?.hash}`}>{txdata?.hash !== undefined ? [truncate(txHash, 4) + txHash.substr(-4, 4)] : ["0x...346 "]} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg></a> <br /><br />
                    txer are happy! now it has a job to deliver your transaction.
                </div>
            </Popup>
            <div className="footer">
                <p>
                    2023 © txers
                </p>
            </div>
        </>
    )
}

export { MintPage }