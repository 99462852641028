/* eslint-disable react/jsx-no-comment-textnodes */
import { MusicComp } from "../components/music"

const StoryPage = () => {
    
    return (
        <>
            <div className="container d-flex justify-content-center" >
                <div className="mint-controller">
                    <div className="mint-message">
                        // lore has been successfully loaded. Tx confirmed.
                    </div>
                    <div className="mint-message">
                        // press enter to read
                    </div>
                    <div className="store-img">
                        <img src="img/1.png" alt="1" />
                    </div>
                    <div className="mint-message">
                        // The year is 2155, the Blockchain is like an abandoned ice planet, cold and static, where not a single byte moves. Researchers say there are indications that life used to boil here, but something happened, and all transactions stopped. The Blockchain seemed to have lost its spark, its energy and its life force. The creatures that had once inhabited it, the miners and nodes, had died out, and the Blockchain had become a barren and desolate wasteland.
                    </div>
                    <div className="mint-message" >
                        // It remained a mystery what caused the sudden cessation of activity and death of the Blockchain's inhabitants. Some speculated that it was a lack of interest or understanding of the technology, while others believed that a catastrophic event caused the death of the Blockchain. Whatever the cause, Blockchain remained dormant, a mystery shrouded in mystery, known only to a select few who had the knowledge to unlock its secrets.
                    </div>
                    <div className="mint-message">
                        // For years, blockchain lay motionless, like a still pond on a summer day. And yet the Txers came, from where - no one can say. Whether they were blockchain natives or outsiders from foreign lands, they saw the missing piece, the vital cog that was malfunctioning.
                    </div>
                    <div className="mint-message">
                        // The infrastructure for movement was in place (digital gas tanks, hash space, interconnections with previous blocks until the last block was mined), but everything was motionless, like a chessboard without a player. But the Txers came in, moved the pieces, and the blockchain came alive like a phoenix rising from the ashes. Why they faded away, no one can say, but now it is time to put the blockchain back in motion...
                    </div>
                    <div className="store-img">
                        <img src="img/2.png" alt="2" />
                    </div>
                    <div className="mint-message">
                        // The Txers realized that they were the missing piece in the grand design. The gears began to turn, and the process of reviving the system was set in motion. The final frozen block and the last transaction, long lost for a century and twenty, were found anew.
                    </div>
                    <div className="mint-message">
                        // Self-organization swiftly emerged, some Txers excelling in the art of archaeology, others becoming masters of analysis, guiding the way forward. Together, they reconstructed the principles of the blockchain, understanding the power of their abilities to trigger transactions. All that was needed was but a mere spark, or «gas» as it were.
                    </div>
                    <div className="mint-message" style={{ color: "#F2994A" }}>
                        // own at least 1 txer to unlock the next chapter
                    </div>
                </div>
            </div>
            <MusicComp/>
            <div className="footer" style={{ position: "relative" }}>
                <p>
                    2023 © txers
                </p>
            </div>
        </>
    )
}

export { StoryPage }