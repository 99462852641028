import React from "react";

const Popup = ({ active, setActive, children }) => {
    return (
        <div className={active ? "popup active" : "popup"} onClick={() => setActive(false)}>
            <div className="popup-content" onClick={e => e.stopPropagation()}>
                <div className="popup-exit" onClick={() => setActive(false)}>
                    <img src="img/exit.svg" alt="Exit" />
                </div>
                {children}
            </div>
        </div>
    )

}

export default Popup;